import { BrowserRouter, Routes, Route } from "react-router-dom";

import { useKeycloak } from '@react-keycloak/web'

import { ApolloProvider } from "@apollo/client";
import { useApollo } from "./ApolloClient/apollo";


import { PrivateRoute } from "./utilities/PrivateRoute";

import Home from './/Home';
import Content from './/Content';
import Links from './Links';
import Contact from './/Contact';
import Authentication from './components/Authentication';
import Agenda from './Agenda';
import Ledenlijst from './Ledenlijst';
import LedenEdit from './LedenEdit';
import LedenAdd from './LedenAdd';
import Diensten from './Diensten';
import NotFound from "./NotFound";


export const AppRouter = () => {
    const {initialized, keycloak} = useKeycloak();
    const client = useApollo(process.env.REACT_APP_CMS_URL+"/graphql", keycloak.authenticated);
  
    if (!initialized) {
      
        return <div className="loading"><div className="lds-ring"><div></div><div></div><div></div><div></div></div></div>
      }

return(
   
        
    <BrowserRouter>
    <ApolloProvider client={client}>
        <Routes>         
            <Route path="/">
                <Route index element={<Home />} />
            </Route>    
            <Route path="/Home">
                <Route index element={<Home />} />
            </Route>
            <Route path="/Links" element={<Links />} />
               
            <Route path="/Contact/:receiver">
                <Route index element={<Contact />} />
            </Route>
            <Route path="/Contact/">
                <Route index element={<Contact />} />
            </Route>
            <Route exact path="/Login" element={<Authentication />}  />
            <Route exact path="/Diensten" element={<Diensten /> } />     
            <Route path="/:url">
                <Route index element={<Content />} />
            </Route>
            <Route path="/OverOns/:url/*">
               <Route index element={<Content />} />
            </Route>
            <Route path="/Verenigingen/:url/*" element={
                <PrivateRoute roles={['Gemeentelid']} >
                    <Content />
            </PrivateRoute>
            }
            />

             <Route path="/Leden/Agenda/" element={
                <PrivateRoute roles={['Gemeentelid']} >
                    <Agenda />
            </PrivateRoute>
            }
            />
            <Route path="/Leden/Adressen/" element={
                <PrivateRoute roles={['Gemeentelid']} >
                    <Ledenlijst />
            </PrivateRoute>
            }
            />
            <Route path="/Leden/:url/*" element={
                <PrivateRoute roles={['Gemeentelid']} >
                    <Content />
            </PrivateRoute>
            }
            />
            <Route path="/Leden/Edit/:id/*" element={
                <PrivateRoute roles={['Ledenadmin']} >
                    <LedenEdit />
            </PrivateRoute>            
            }
            />
            <Route path="/Leden/Add/" element={
                <PrivateRoute roles={['Ledenadmin']} >
                    <LedenAdd />
            </PrivateRoute>
            }            
            />
            <Route path="*" element={<NotFound />}m /> 
        </Routes>
        </ApolloProvider>
        </BrowserRouter>
   
);
};